@import "./Foundation/variables";
@import "./Foundation/mediaqueries";
@import "./Foundation/fontsize";

*,
body {
  font-family: $font-default;
  letter-spacing: 0.1em;
  color: $color-default;
  font-weight: 500;

  @include pc {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
  font-feature-settings: "palt";

  @include pc {
    scroll-padding-top: 170px;
  }
}

body {
  color: $color-light;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: $color-default;
  transition: all 0.4s;
}

.sp {
  display: block !important;

  @include pc() {
    display: none !important;
  }
}

.pc {
  display: none !important;

  @include pc() {
    display: block !important;
  }
}

.container {
  overflow: hidden;
}

.accordion {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.accordion-title {
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 28px 20px;
}

.accordion-content {
  display: none;
  padding: 10px 20px;
}

.accordion-content p {
  font-size: 14px;
  line-height: 1.5;
}

/* 矢印 */
.accordion-title {
  position: relative;
}

.accordion-title {

  &::after,
  &::before {
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: 25px;
    top: 50%;
    background: #333;
    transition: transform .3s ease-in-out, top .3s ease-in-out;
    width: 20px;
  }

  &::before {
    transform: rotate(90deg);
  }
}

.accordion-title.open::before {
  display: none;
}

.c-btn {
  display: block;
  width: 305px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  border-radius: 25px;
  border: 1px solid $color-pink;
  position: relative;
  cursor: pointer;
  letter-spacing: 0.06em;
  background: #fff;

  @include pc {
    width: 320px;
  }

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    background: url(../img/common/btn-arrow.png) no-repeat center top/cover;
    position: absolute;
    right: 10px;
    transition: transform .3s .1s cubic-bezier(.15, .62, .43, .8);
  }

  &::before {
    content: '';
    width: 30px;
    height: 30px;
    background: url(../img/common/btn-arrow.png) no-repeat center top/cover;
    transition: transform .3s .1s cubic-bezier(.15, .62, .43, .8);
    position: absolute;
    left: 10px;
    transform: scale(0);
  }

  &:hover {
    background: $color-pink;
    color: #fff;
    padding-left: 50px;

    &::after {
      transform: scale(0);
    }

    &::before {
      transform: scale(1);
    }
  }

  &.pink {
    background: $color-pink;
    color: #fff;

    &::after {
      background: url(../img/common/btn-arrow-white.png) no-repeat center top/cover;
    }

    &::before {
      display: none;
    }

    &:hover {
      padding: 0 20px;

      &::after {
        transform: scale(1);
      }

      &::before {
        display: none;
      }
    }
  }
}

.page-kasou {
  .mv-area {
    padding: 145px 0 80px;
    position: relative;

    @include pc {
      padding: 160px 0 100px;
    }

    &::before {
      content: '';
      display: block;
      width: 178px;
      height: 152px;
      background: url(../img/common/mv-deco-sp.png) no-repeat center top/cover;
      position: absolute;
      top: 0;
      right: 0;

      @include pc {
        width: 427px;
        height: 262px;
      }
    }

    .sec-ttl {
      padding: 0 0 0 15px;
      font-size: 32px;
      color: $color-pink;
      font-family: $font-jost;
      font-weight: 400;
      margin-bottom: 18px;

      @include pc {
        font-size: 55px;
        max-width: 1000px;
        margin: 0 auto 30px;
        padding: 0;
      }
    }

    .breadcrumb {
      font-size: 10px;
      padding-left: 15px;
      line-height: 1.8;
      margin-bottom: 16px;

      @include pc {
        max-width: 1000px;
        margin: 0 auto 18px;
        font-size: 12px;
        padding: 0;
      }

      a {
        position: relative;
        margin-right: 10px;
        padding-right: 10px;

        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background: #333;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .top-link {
        text-decoration: underline;
        color: $color-pink;
      }
    }

    .deco {
      height: 92px;
      width: 367px;
      background: url(../img/common/mv-title-deco-sp.png) no-repeat center top/cover;
      position: relative;

      @include pc {
        height: 234px;
        width: 1282px;
        background: url(../img/common/mv-title-deco.png) no-repeat center top/cover;
      }

      span {
        color: #fff;
        font-size: 18px;
        line-height: 1.2;
        position: absolute;
        left: 15px;
        bottom: 20px;

        @include pc {
          max-width: 1000px;
          display: block;
          margin-left: 23%;
          font-size: 34px;
          line-height: calc(53 / 34);
          bottom: 42px;
          letter-spacing: 0.08em;
        }
      }

      small {
        font-size: 10px;
        color: #fff;
        display: block;

        @include pc {
          font-size: 22px;
        }
      }

      &.twocoloum {
        span {
          bottom: 6px;

          @include pc {
            bottom: 10px;
          }
        }
      }

      &.twocoloum-sp {
        span {
          bottom: 6px;

          @include pc {
            bottom: 42px;
          }
        }
      }
    }
  }
}

.ttl-area {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -13px;
  width: 100%;

  @include pc {
    top: -20px;
  }

  h2 {
    font-size: 19px;
    padding: 3px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
    top: -17px;
    background: linear-gradient(180deg, transparent 0%, transparent 47%, #fff 47%, #fff 100%);

    @include pc {
      font-size: 34px;
      padding: 0 28px;
      top: -28px;
    }
  }

  .ttl-deco {
    width: 98%;
    margin-left: auto;

    @include pc {
      width: 83%;
    }
  }

  .en {
    font-family: $font-jost;
    font-size: 12px;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    z-index: 2;
    bottom: -12px;

    span {
      display: inline-block;
      -webkit-clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
      clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
      background: rgb(255, 175, 209);
      background: linear-gradient(90deg, rgba(255, 175, 209, 1) 0%, rgba(238, 122, 170, 1) 100%);
      padding: 2px 8px;
      color: #fff;

      @include pc {
        font-size: 14px;
        padding: 2px 16px;
      }
    }
  }

  &.right {
    .ttl-deco {
      margin: 0;
      transform: scale(-1, 1);
    }
  }
}

.section {
  padding: 50px 15px 100px;
  position: relative;

  @include pc {
    padding: 80px 15px 190px;
  }

  .bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #fef8fb;
    top: 0;
    left: 0;
    border-radius: 0 0 50px 0;

    @include pc {
      border-radius: 0 0 100px 0;
    }

    &.two {
      border-radius: 50px 0 0 0;

      @include pc {
        border-radius: 100px 0 0 0;
      }
    }
  }

  .desc {
    line-height: 2;
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 0.05em;

    @include pc {
      margin-bottom: 50px;
    }
  }
}

.news-wrapper {
  padding: 0 45px;

  @include pc {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }

  .news-content {
    margin-bottom: 60px;

    a {
      @include pc {
        display: flex;
        align-items: flex-start;
      }
    }

    figure {
      overflow: hidden;
      position: relative;
      padding-top: 55.128%;
      width: 100%;
      margin-bottom: 24px;

      @include pc {
        width: 314px;
        padding-top: 20%;
        flex-shrink: 0;
        margin: 0 24px 0 0;
      }

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        -o-object-fit: cover;
        object-fit: cover;
      }

      .category {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: auto;
        padding: 0 40px 0 10px;
        height: 18px;
        background: $color-pink;
        background: linear-gradient(90deg, rgba(255, 114, 174, 1) 0%, rgba(232, 72, 138, 1) 100%);
        font-size: 10px;
        line-height: 18px;
        text-align: center;
        -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);

        span {
          color: #fff;
        }
      }
    }

    .txt {
      .date {
        color: $color-pink;
        font-family: $font-jost;
        font-weight: 400;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        font-size: 14px;

        &::before {
          content: '';
          display: block;
          width: 60px;
          height: 1px;
          background: $color-pink;
          margin-right: 10px;
        }
      }

      .title {
        margin-bottom: 1em;
      }

      .content {
        font-size: 14px;
        letter-spacing: 0.06em;
      }
    }
  }
}