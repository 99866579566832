@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  word-wrap: break-word;
}

input,
textarea {
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -webkit-text-size-adjust: 100%;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: bottom;
}

*,
body {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
  letter-spacing: 0.1em;
  color: #333;
  font-weight: 500;
}
@media only screen and (min-width: 821px) {
  *,
  body {
    letter-spacing: 0.14em;
  }
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
  font-feature-settings: "palt";
}
@media only screen and (min-width: 821px) {
  html {
    scroll-padding-top: 170px;
  }
}

body {
  color: #fff;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
dt,
dd,
th,
td,
span,
a {
  line-height: 1.6;
}

img {
  width: 100%;
  vertical-align: bottom;
}

a {
  color: #333;
  transition: all 0.4s;
}

.sp {
  display: block !important;
}
@media only screen and (min-width: 821px) {
  .sp {
    display: none !important;
  }
}

.pc {
  display: none !important;
}
@media only screen and (min-width: 821px) {
  .pc {
    display: block !important;
  }
}

.container {
  overflow: hidden;
}

.accordion {
  margin: 0 auto;
  position: relative;
  width: 100%;
}

.accordion-title {
  color: #333;
  cursor: pointer;
  font-size: 16px;
  padding: 28px 20px;
}

.accordion-content {
  display: none;
  padding: 10px 20px;
}

.accordion-content p {
  font-size: 14px;
  line-height: 1.5;
}

/* 矢印 */
.accordion-title {
  position: relative;
}

.accordion-title::after, .accordion-title::before {
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  right: 25px;
  top: 50%;
  background: #333;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
  width: 20px;
}
.accordion-title::before {
  transform: rotate(90deg);
}

.accordion-title.open::before {
  display: none;
}

.c-btn {
  display: block;
  width: 305px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  border-radius: 25px;
  border: 1px solid #e8488a;
  position: relative;
  cursor: pointer;
  letter-spacing: 0.06em;
  background: #fff;
}
@media only screen and (min-width: 821px) {
  .c-btn {
    width: 320px;
  }
}
.c-btn::after {
  content: "";
  width: 30px;
  height: 30px;
  background: url(../img/common/btn-arrow.png) no-repeat center top/cover;
  position: absolute;
  right: 10px;
  transition: transform 0.3s 0.1s cubic-bezier(0.15, 0.62, 0.43, 0.8);
}
.c-btn::before {
  content: "";
  width: 30px;
  height: 30px;
  background: url(../img/common/btn-arrow.png) no-repeat center top/cover;
  transition: transform 0.3s 0.1s cubic-bezier(0.15, 0.62, 0.43, 0.8);
  position: absolute;
  left: 10px;
  transform: scale(0);
}
.c-btn:hover {
  background: #e8488a;
  color: #fff;
  padding-left: 50px;
}
.c-btn:hover::after {
  transform: scale(0);
}
.c-btn:hover::before {
  transform: scale(1);
}
.c-btn.pink {
  background: #e8488a;
  color: #fff;
}
.c-btn.pink::after {
  background: url(../img/common/btn-arrow-white.png) no-repeat center top/cover;
}
.c-btn.pink::before {
  display: none;
}
.c-btn.pink:hover {
  padding: 0 20px;
}
.c-btn.pink:hover::after {
  transform: scale(1);
}
.c-btn.pink:hover::before {
  display: none;
}

.page-kasou .mv-area {
  padding: 145px 0 80px;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area {
    padding: 160px 0 100px;
  }
}
.page-kasou .mv-area::before {
  content: "";
  display: block;
  width: 178px;
  height: 152px;
  background: url(../img/common/mv-deco-sp.png) no-repeat center top/cover;
  position: absolute;
  top: 0;
  right: 0;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area::before {
    width: 427px;
    height: 262px;
  }
}
.page-kasou .mv-area .sec-ttl {
  padding: 0 0 0 15px;
  font-size: 32px;
  color: #e8488a;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  margin-bottom: 18px;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .sec-ttl {
    font-size: 55px;
    max-width: 1000px;
    margin: 0 auto 30px;
    padding: 0;
  }
}
.page-kasou .mv-area .breadcrumb {
  font-size: 10px;
  padding-left: 15px;
  line-height: 1.8;
  margin-bottom: 16px;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .breadcrumb {
    max-width: 1000px;
    margin: 0 auto 18px;
    font-size: 12px;
    padding: 0;
  }
}
.page-kasou .mv-area .breadcrumb a {
  position: relative;
  margin-right: 10px;
  padding-right: 10px;
}
.page-kasou .mv-area .breadcrumb a::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background: #333;
  position: absolute;
  top: 0;
  right: 0;
}
.page-kasou .mv-area .breadcrumb .top-link {
  text-decoration: underline;
  color: #e8488a;
}
.page-kasou .mv-area .deco {
  height: 92px;
  width: 367px;
  background: url(../img/common/mv-title-deco-sp.png) no-repeat center top/cover;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .deco {
    height: 234px;
    width: 1282px;
    background: url(../img/common/mv-title-deco.png) no-repeat center top/cover;
  }
}
.page-kasou .mv-area .deco span {
  color: #fff;
  font-size: 18px;
  line-height: 1.2;
  position: absolute;
  left: 15px;
  bottom: 20px;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .deco span {
    max-width: 1000px;
    display: block;
    margin-left: 23%;
    font-size: 34px;
    line-height: 1.5588235294;
    bottom: 42px;
    letter-spacing: 0.08em;
  }
}
.page-kasou .mv-area .deco small {
  font-size: 10px;
  color: #fff;
  display: block;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .deco small {
    font-size: 22px;
  }
}
.page-kasou .mv-area .deco.twocoloum span {
  bottom: 6px;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .deco.twocoloum span {
    bottom: 10px;
  }
}
.page-kasou .mv-area .deco.twocoloum-sp span {
  bottom: 6px;
}
@media only screen and (min-width: 821px) {
  .page-kasou .mv-area .deco.twocoloum-sp span {
    bottom: 42px;
  }
}

.ttl-area {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: -13px;
  width: 100%;
}
@media only screen and (min-width: 821px) {
  .ttl-area {
    top: -20px;
  }
}
.ttl-area h2 {
  font-size: 19px;
  padding: 3px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 2;
  top: -17px;
  background: linear-gradient(180deg, transparent 0%, transparent 47%, #fff 47%, #fff 100%);
}
@media only screen and (min-width: 821px) {
  .ttl-area h2 {
    font-size: 34px;
    padding: 0 28px;
    top: -28px;
  }
}
.ttl-area .ttl-deco {
  width: 98%;
  margin-left: auto;
}
@media only screen and (min-width: 821px) {
  .ttl-area .ttl-deco {
    width: 83%;
  }
}
.ttl-area .en {
  font-family: "Jost", sans-serif;
  font-size: 12px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  z-index: 2;
  bottom: -12px;
}
.ttl-area .en span {
  display: inline-block;
  -webkit-clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
  clip-path: polygon(5% 0, 100% 0%, 95% 100%, 0% 100%);
  background: rgb(255, 175, 209);
  background: linear-gradient(90deg, rgb(255, 175, 209) 0%, rgb(238, 122, 170) 100%);
  padding: 2px 8px;
  color: #fff;
}
@media only screen and (min-width: 821px) {
  .ttl-area .en span {
    font-size: 14px;
    padding: 2px 16px;
  }
}
.ttl-area.right .ttl-deco {
  margin: 0;
  transform: scale(-1, 1);
}

.section {
  padding: 50px 15px 100px;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .section {
    padding: 80px 15px 190px;
  }
}
.section .bg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: #fef8fb;
  top: 0;
  left: 0;
  border-radius: 0 0 50px 0;
}
@media only screen and (min-width: 821px) {
  .section .bg {
    border-radius: 0 0 100px 0;
  }
}
.section .bg.two {
  border-radius: 50px 0 0 0;
}
@media only screen and (min-width: 821px) {
  .section .bg.two {
    border-radius: 100px 0 0 0;
  }
}
.section .desc {
  line-height: 2;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 0.05em;
}
@media only screen and (min-width: 821px) {
  .section .desc {
    margin-bottom: 50px;
  }
}

.news-wrapper {
  padding: 0 45px;
}
@media only screen and (min-width: 821px) {
  .news-wrapper {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0;
  }
}
.news-wrapper .news-content {
  margin-bottom: 60px;
}
@media only screen and (min-width: 821px) {
  .news-wrapper .news-content a {
    display: flex;
    align-items: flex-start;
  }
}
.news-wrapper .news-content figure {
  overflow: hidden;
  position: relative;
  padding-top: 55.128%;
  width: 100%;
  margin-bottom: 24px;
}
@media only screen and (min-width: 821px) {
  .news-wrapper .news-content figure {
    width: 314px;
    padding-top: 20%;
    flex-shrink: 0;
    margin: 0 24px 0 0;
  }
}
.news-wrapper .news-content figure img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
}
.news-wrapper .news-content figure .category {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: auto;
  padding: 0 40px 0 10px;
  height: 18px;
  background: #e8488a;
  background: linear-gradient(90deg, rgb(255, 114, 174) 0%, rgb(232, 72, 138) 100%);
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  -webkit-clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}
.news-wrapper .news-content figure .category span {
  color: #fff;
}
.news-wrapper .news-content .txt .date {
  color: #e8488a;
  font-family: "Jost", sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.news-wrapper .news-content .txt .date::before {
  content: "";
  display: block;
  width: 60px;
  height: 1px;
  background: #e8488a;
  margin-right: 10px;
}
.news-wrapper .news-content .txt .title {
  margin-bottom: 1em;
}
.news-wrapper .news-content .txt .content {
  font-size: 14px;
  letter-spacing: 0.06em;
}

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

body.is-drawerActive .l-header .inner .logo-wrap {
  opacity: 0;
}

.l-header {
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
}
.l-header .inner {
  padding: 18px 80px 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
@media only screen and (min-width: 821px) {
  .l-header .inner {
    padding: 20px 30px;
  }
}
.l-header .inner .nav-sp .sns-wrapper {
  display: flex;
  align-items: center;
}
.l-header .inner .nav-sp .sns-wrapper a {
  display: block;
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.l-header .logo-wrap {
  position: relative;
  z-index: 9999;
  flex-shrink: 0;
  margin-right: 20px;
}
.l-header .logo-wrap img {
  width: auto;
  height: 34px;
}
@media only screen and (min-width: 821px) {
  .l-header .logo-wrap img {
    height: 45px;
  }
}
@media only screen and (min-width: 821px) {
  .l-header .nav_wrapper {
    display: flex;
    align-items: center;
  }
}
@media only screen and (min-width: 821px) {
  .l-header .nav_pc ul {
    display: flex;
    align-items: center;
  }
  .l-header .nav_pc ul li:not(.l-header .nav_pc ul li:last-child) {
    margin-right: 30px;
  }
  .l-header .nav_pc ul li.insta {
    width: 40px;
    height: 40px;
  }
  .l-header .nav_pc ul li.line a img {
    width: auto;
    height: 60px;
  }
  .l-header .nav_pc ul li a {
    color: #333;
    font-size: clamp(1vw, 1.2vw, 16px);
  }
}
.l-header .humburger-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@media only screen and (min-width: 821px) {
  .l-header .humburger-wrap {
    display: block;
    height: 60px;
  }
}
.l-header .humburger-wrap .button-wrap {
  display: flex;
  align-items: center;
}
.l-header .humburger-wrap .button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 100;
}
.l-header .humburger-wrap .hamburger {
  width: 70px;
  height: 70px;
  outline: none;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
  background: rgb(255, 114, 174);
  background: linear-gradient(90deg, rgb(255, 114, 174) 0%, rgb(232, 72, 138) 100%);
  position: absolute;
  top: 0;
  right: 0;
}
.l-header .humburger-wrap .hamburger-line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 36px;
  height: 2px;
  background-color: transparent;
  transition: inherit;
}
.l-header .humburger-wrap .hamburger-line::before,
.l-header .humburger-wrap .hamburger-line::after {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  content: "";
  transition: inherit;
  left: 50%;
  transform: translateX(-50%);
}
.l-header .humburger-wrap .hamburger-line::before {
  width: 22px;
  top: 3px;
}
.l-header .humburger-wrap .hamburger-line::after {
  width: 22px;
  top: -3px;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line {
  width: 18px;
  background-color: transparent;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before,
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  width: 31px;
  top: 0px;
  right: -6px;
  left: initial;
  background-color: #fff;
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::before {
  transform: rotate(35deg);
}
.l-header .humburger-wrap .hamburger[aria-expanded=true] .hamburger-line::after {
  transform: rotate(-35deg);
}
.l-header .humburger-wrap .visuallyHidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}
.l-header .nav-wrap {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  overflow-y: scroll;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 3;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
  }
}
.l-header .nav-wrap .nav_sp {
  width: 90%;
  margin-left: auto;
  padding: 70px 0 70px 36px;
  background-color: #fff;
  border-radius: 30px 0 0 30px;
}
@media only screen and (min-width: 821px) {
  .l-header .nav-wrap .nav_sp {
    width: 34%;
  }
}
.l-header .nav-wrap .nav_sp ul li {
  margin-bottom: 20px;
}
.l-header .nav-wrap .nav_sp ul li:last-child {
  margin-bottom: 0;
}
.l-header .nav-wrap .nav_sp ul li a {
  color: #e8488a;
  font-weight: 500;
}

footer {
  padding: 80px 0 28px;
  background: #f8f5f5;
  position: relative;
}
@media only screen and (min-width: 821px) {
  footer {
    padding: 70px 0 48px;
  }
}
footer .to-top {
  width: 45px;
  height: 45px;
  position: absolute;
  right: 20px;
  top: -20px;
}
@media only screen and (min-width: 821px) {
  footer .to-top {
    right: 5%;
  }
}
footer .footer-top {
  margin-bottom: 24px;
}
@media only screen and (min-width: 821px) {
  footer .footer-top {
    max-width: 1330px;
    margin: 0 auto 40px;
    position: relative;
  }
}
footer .footer-top .logo-wrap {
  margin-bottom: 20px;
}
@media only screen and (min-width: 821px) {
  footer .footer-top .logo-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  }
}
footer .footer-top .logo-wrap a {
  margin-bottom: 10px;
  display: block;
}
@media only screen and (min-width: 821px) {
  footer .footer-top .logo-wrap a {
    margin: 0 20px 0 0;
  }
}
footer .footer-top .logo-wrap a img {
  height: 50px;
  width: auto;
  display: block;
  margin: 0 auto;
}
footer .footer-top .logo-wrap p {
  text-align: center;
}
footer .footer-top .info {
  padding: 0 15px;
}
@media only screen and (min-width: 821px) {
  footer .footer-top .info {
    display: flex;
    padding: 0;
  }
}
footer .footer-top .info .info-content {
  padding-left: 28px;
  margin-bottom: 18px;
}
@media only screen and (min-width: 821px) {
  footer .footer-top .info .info-content {
    margin: 0 30px 0 0;
  }
}
footer .footer-top .info .info-content h3 {
  margin-bottom: 10px;
  position: relative;
}
footer .footer-top .info .info-content h3::before, footer .footer-top .info .info-content h3::after {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #333;
  position: absolute;
  top: 13px;
  transform: translateY(-50%);
  left: -28px;
}
footer .footer-top .info .info-content h3::after {
  width: 6px;
  height: 6px;
  background: #fff;
  left: -23px;
}
footer .footer-top .info .info-content p {
  font-size: 14px;
}
footer .footer-top .sns-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 821px) {
  footer .footer-top .sns-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }
}
footer .footer-top .sns-wrapper a {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
footer .footer-top .sns-wrapper a:last-child {
  margin-right: 0;
}
footer .footer-bottom {
  border-top: 1px solid #333;
}
@media only screen and (min-width: 821px) {
  footer .footer-bottom {
    padding-top: 80px;
  }
}
footer .footer-bottom .accordion {
  margin-bottom: 30px;
}
footer .footer-bottom .accordion-title {
  border-bottom: 1px solid #333;
}
footer .footer-bottom .accordion-content {
  background: #ebe9e9;
  padding: 30px 20px;
}
footer .footer-bottom .accordion-content ul li a {
  font-size: 14px;
  display: flex;
  align-items: center;
}
footer .footer-bottom .accordion-content ul li a::before {
  content: "";
  display: block;
  width: 9px;
  height: 14px;
  background: #333;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  margin-right: 4px;
}
footer .footer-bottom .link-applay {
  display: block;
  padding: 20px 0;
  text-align: center;
  color: #fff;
  background: #333;
}
footer .footer-copy {
  margin: 20px 0 0;
}
@media only screen and (min-width: 821px) {
  footer .footer-copy {
    display: flex;
    justify-content: space-between;
    max-width: 1330px;
    margin: 48px auto 0;
  }
}
footer .footer-copy ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
@media only screen and (min-width: 821px) {
  footer .footer-copy ul {
    margin-bottom: 0;
  }
}
footer .footer-copy ul li {
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
}
footer .footer-copy ul li::after {
  content: "";
  display: block;
  width: 1px;
  height: 60%;
  background: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
footer .footer-copy ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}
footer .footer-copy ul li:last-child::after {
  display: none;
}
footer .footer-copy ul li a {
  font-size: 12px;
}
footer .footer-copy .copy {
  color: #e8488a;
  font-size: 12px;
  text-align: center;
}
footer .tab-list {
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 1330px;
  margin: 0 auto;
}
footer .tab-list__item {
  width: 30%;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
}
footer .tab-list__item::before, footer .tab-list__item::after {
  content: "";
  display: block;
  width: 14px;
  height: 1px;
  background: #333;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
footer .tab-list__item::after {
  transform: translateY(-50%) rotate(90deg);
}
footer .tab-list__item.application-link {
  background: #333;
  text-align: center;
  color: #fff;
}
footer .tab-list__item.active {
  border-bottom: 1px solid #333;
}
footer .tab-list__item.active::after {
  display: none;
}
footer .tab-contents__item {
  display: none;
  background: #ebe9e9;
  padding: 30px 0;
}
footer .tab-contents__item ul {
  display: flex;
  justify-content: center;
}
footer .tab-contents__item ul li {
  margin-right: 10px;
}
footer .tab-contents__item ul li a {
  font-size: 14px;
  display: flex;
  align-items: center;
}
footer .tab-contents__item ul li a::before {
  content: "";
  display: block;
  width: 9px;
  height: 14px;
  background: #333;
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
  clip-path: polygon(0 0, 0% 100%, 100% 50%);
  margin-right: 4px;
}
footer .tab-contents__item.show {
  display: block;
  -webkit-animation: fadeIn 0.5s ease;
  animation: fadeIn 0.5s ease;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}