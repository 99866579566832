$color-default: #333;
$color-light: #fff;
$color-pink: #e8488a;


$font-default: "游ゴシック体",
"Yu Gothic",
YuGothic,
"ヒラギノ角ゴ Pro",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
"MS Pゴシック",
"MS PGothic",
sans-serif;
$font-poppins: "Poppins",
sans-serif;
$font-mont: "Montserrat",
sans-serif;
$font-jost: "Jost",
sans-serif;