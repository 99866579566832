@import "../Foundation/_mediaqueries";

body.is-drawerActive {
  overflow-y: hidden;
}

body.is-drawerActive .l-header .nav-wrap {
  opacity: 1;
  visibility: visible;
}

body.is-drawerActive .l-header .inner .logo-wrap {
  opacity: 0;
}

.l-header {
  width: 100%;
  background: transparent;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;

  .inner {
    padding: 18px 80px 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include pc() {
      padding: 20px 30px;
    }

    .nav-sp {
      .sns-wrapper {
        display: flex;
        align-items: center;

        a {
          display: block;
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }
      }
    }
  }

  .logo-wrap {
    position: relative;
    z-index: 9999;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      width: auto;
      height: 34px;

      @include pc {
        height: 45px;
      }
    }
  }

  .nav_wrapper {
    @include pc {
      display: flex;
      align-items: center;
    }
  }

  .nav_pc {
    @include pc {
      ul {
        display: flex;
        align-items: center;

        li {
          &:not(&:last-child) {
            margin-right: 30px;
          }

          &.insta {
            width: 40px;
            height: 40px;
          }

          &.line {
            a {
              img {
                width: auto;
                height: 60px;
              }
            }
          }

          a {
            color: $color-default;
            font-size: clamp(1vw, 1.2vw, 16px);
          }
        }
      }
    }
  }

  .humburger-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    @include pc() {
      display: block;
      height: 60px;
    }

    .button-wrap {
      display: flex;
      align-items: center;
    }

    .button {
      position: relative;
      display: inline-block;
      text-decoration: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 100;
    }

    .hamburger {
      width: 70px;
      height: 70px;
      outline: none;
      transition: all 0.3s ease-in-out;
      margin-bottom: 0;
      background: rgb(255, 114, 174);
      background: linear-gradient(90deg, rgba(255, 114, 174, 1) 0%, rgba(232, 72, 138, 1) 100%);
      position: absolute;
      top: 0;
      right: 0;
    }

    .hamburger-line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 36px;
      height: 2px;
      background-color: transparent;
      transition: inherit;
    }

    .hamburger-line::before,
    .hamburger-line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #fff;
      content: "";
      transition: inherit;
      left: 50%;
      transform: translateX(-50%);
    }

    .hamburger-line::before {
      width: 22px;
      top: 3px;
    }

    .hamburger-line::after {
      width: 22px;
      top: -3px;
    }

    .hamburger[aria-expanded="true"] .hamburger-line {
      width: 18px;
      background-color: transparent;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before,
    .hamburger[aria-expanded="true"] .hamburger-line::after {
      width: 31px;
      top: 0px;
      right: -6px;
      left: initial;
      background-color: #fff;
    }

    .hamburger[aria-expanded="true"] .hamburger-line::before {
      transform: rotate(35deg);
    }

    .hamburger[aria-expanded="true"] .hamburger-line::after {
      transform: rotate(-35deg);
    }

    .visuallyHidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      -webkit-clip-path: inset(50%);
      clip-path: inset(50%);
      margin: -1px;
    }

  }

  .nav-wrap {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    overflow-y: scroll;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 3;

    @include pc() {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
    }

    .nav_sp {
      width: 90%;
      margin-left: auto;
      padding: 70px 0 70px 36px;
      background-color: #fff;
      border-radius: 30px 0 0 30px;

      @include pc {
        width: 34%;
      }

      ul {
        li {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }

          a {
            color: $color-pink;
            font-weight: 500;
          }
        }
      }
    }
  }
}