@import "../Foundation/_mediaqueries";
@import "../Foundation/fontsize";

footer {
  padding: 80px 0 28px;
  background: #f8f5f5;
  position: relative;

  @include pc {
    padding: 70px 0 48px;
  }

  .to-top {
    width: 45px;
    height: 45px;
    position: absolute;
    right: 20px;
    top: -20px;

    @include pc {
      right: 5%;
    }
  }

  .footer-top {
    margin-bottom: 24px;

    @include pc {
      max-width: 1330px;
      margin: 0 auto 40px;
      position: relative;
    }

    .logo-wrap {
      margin-bottom: 20px;

      @include pc {
        display: flex;
        align-items: center;
        margin-bottom: 48px;
      }

      a {
        margin-bottom: 10px;
        display: block;

        @include pc {
          margin: 0 20px 0 0;
        }

        img {
          height: 50px;
          width: auto;
          display: block;
          margin: 0 auto;
        }
      }

      p {
        text-align: center;
      }
    }

    .info {
      padding: 0 15px;

      @include pc {
        display: flex;
        padding: 0;
      }

      .info-content {
        padding-left: 28px;
        margin-bottom: 18px;

        @include pc {
          margin: 0 30px 0 0;
        }

        h3 {
          margin-bottom: 10px;
          position: relative;

          &::before,
          &::after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #333;
            position: absolute;
            top: 13px;
            transform: translateY(-50%);
            left: -28px;
          }

          &::after {
            width: 6px;
            height: 6px;
            background: #fff;
            left: -23px;
          }
        }

        p {
          font-size: 14px;

        }
      }
    }

    .sns-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      @include pc {
        position: absolute;
        top: 0;
        right: 0;
      }

      a {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .footer-bottom {
    border-top: 1px solid #333;

    @include pc {
      padding-top: 80px;
    }

    .accordion {
      margin-bottom: 30px;
    }

    .accordion-title {
      border-bottom: 1px solid #333;
    }

    .accordion-content {
      background: #ebe9e9;
      padding: 30px 20px;

      ul {
        li {
          a {
            font-size: 14px;
            display: flex;
            align-items: center;

            &::before {
              content: '';
              display: block;
              width: 9px;
              height: 14px;
              background: $color-default;
              -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
              clip-path: polygon(0 0, 0% 100%, 100% 50%);
              margin-right: 4px;
            }
          }
        }
      }
    }

    .link-applay {
      display: block;
      padding: 20px 0;
      text-align: center;
      color: #fff;
      background: #333;
    }
  }

  .footer-copy {
    margin: 20px 0 0;

    @include pc {
      display: flex;
      justify-content: space-between;
      max-width: 1330px;
      margin: 48px auto 0;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      @include pc {
        margin-bottom: 0;
      }

      li {
        padding-right: 10px;
        margin-right: 10px;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 60%;
          background: #333;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 0;

          &::after {
            display: none;
          }
        }

        a {
          font-size: 12px;
        }
      }
    }

    .copy {
      color: $color-pink;
      font-size: 12px;
      text-align: center;
    }
  }

  .tab-list {
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    max-width: 1330px;
    margin: 0 auto;
  }

  .tab-list__item {
    width: 30%;
    padding: 10px 0;
    cursor: pointer;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 1px;
      background: $color-default;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }

    &::after {
      transform: translateY(-50%) rotate(90deg);
    }

    &.application-link {
      background: $color-default;
      text-align: center;
      color: #fff;
    }
  }

  .tab-list__item.active {
    border-bottom: 1px solid $color-default;

    &::after {
      display: none;
    }
  }

  .tab-contents__item {
    display: none;
    background: #ebe9e9;
    padding: 30px 0;

    ul {
      display: flex;
      justify-content: center;

      li {
        margin-right: 10px;

        a {
          font-size: 14px;
          display: flex;
          align-items: center;

          &::before {
            content: '';
            display: block;
            width: 9px;
            height: 14px;
            background: $color-default;
            -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            margin-right: 4px;
          }
        }
      }
    }
  }

  .tab-contents__item.show {
    display: block;
    -webkit-animation: fadeIn 0.5s ease;
    animation: fadeIn 0.5s ease;
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}